import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route
        path={`${match.url}dashboard`}
        component={asyncComponent(() => import("./Dashboard"))}
      />
      <Route
        path={`${match.url}reports`}
        exact
        component={asyncComponent(() => import("./Report"))}
      />
      <Route
        path={`${match.url}reports/top-selling-items`}
        component={asyncComponent(() => import("./Report/TopSellingItems"))}
      />
      <Route
        path={`${match.url}reports/sales-comparison`}
        component={asyncComponent(() => import("./Report/SalesComparison"))}
      />
      <Route
        path={`${match.url}reports/sales-tax`}
        component={asyncComponent(() => import("./Report/SalesTax"))}
      />
      <Route
        path={`${match.url}reports/jobs-completed`}
        component={asyncComponent(() => import("./Report/JobsCompleted"))}
      />
      <Route
        path={`${match.url}reports/sales-tax-exemption`}
        component={asyncComponent(() => import("./Report/SalesTaxExemption"))}
      />
      <Route
        path={`${match.url}reports/sales-tax-summarization`}
        component={asyncComponent(() =>
          import("./Report/SalesTaxSummarization")
        )}
      />
      <Route
        path={`${match.url}reports/outstanding-orders`}
        component={asyncComponent(() => import("./Report/OutstandingOrders"))}
      />
      <Route
        path={`${match.url}reports/payment-transactions`}
        component={asyncComponent(() =>
          import("./Report/PaymentTransactionReport")
        )}
      />
      <Route
        path={`${match.url}reports/order-error-report`}
        component={asyncComponent(() => import("./Report/OrderErrorReport"))}
      />
      <Route
        path={`${match.url}reports/order-report-by-total`}
        component={asyncComponent(() => import("./Report/OrderReportByTotal"))}
      />
      <Route
        path={`${match.url}user`}
        component={asyncComponent(() => import("./User"))}
      />
      <Route
        path={`${match.url}addUser`}
        component={asyncComponent(() => import("./User/AddUser"))}
      />
      <Route
        path={`${match.url}editUser`}
        component={asyncComponent(() => import("./User/EditUser"))}
      />
      <Route
        path={`${match.url}roles$Permissions`}
        component={asyncComponent(() => import("./RolesPermission"))}
      />
      <Route
        path={`${match.url}addRole`}
        component={asyncComponent(() => import("./RolesPermission/AddRole"))}
      />
      <Route
        path={`${match.url}editRole`}
        component={asyncComponent(() => import("./RolesPermission/EditRole"))}
      />
      <Route
        path={`${match.url}vendors`}
        component={asyncComponent(() => import("./Vendors"))}
      />
      <Route
        path={`${match.url}addVendor`}
        component={asyncComponent(() => import("./Vendors/AddVendor"))}
      />
      <Route
        path={`${match.url}editVendor`}
        component={asyncComponent(() => import("./Vendors/EditVendor"))}
      />
      <Route
        path={`${match.url}partners`}
        component={asyncComponent(() => import("./Partners"))}
      />
      <Route
        path={`${match.url}addPartners`}
        component={asyncComponent(() => import("./Partners/AddPartners"))}
      />
      <Route
        path={`${match.url}editPartners`}
        component={asyncComponent(() => import("./Partners/EditPartners"))}
      />
      <Route
        path={`${match.url}employees`}
        component={asyncComponent(() => import("./Employees"))}
      />
      <Route
        path={`${match.url}editOrder`}
        component={asyncComponent(() => import("./EditOrder"))}
      />
      <Route
        path={`${match.url}productModules`}
        component={asyncComponent(() => import("./ProductsModule"))}
      />
      <Route
        path={`${match.url}orderDetails`}
        component={asyncComponent(() => import("./OrderDetails"))}
      />
      <Route
        path={`${match.url}ordersList`}
        component={asyncComponent(() => import("./OrdersList"))}
      />
      <Route
        path={`${match.url}newOrders`}
        component={asyncComponent(() => import("./OrdersList"))}
      />
      <Route
        path={`${match.url}categories`}
        component={asyncComponent(() => import("./Categories"))}
      />
      <Route
        path={`${match.url}variations`}
        component={asyncComponent(() => import("./Variations"))}
      />
      <Route
        path={`${match.url}contactus`}
        exact
        component={asyncComponent(() => import("./ContactUs"))}
      />
      <Route
        path={`${match.url}contactus/:id`}
        exact
        component={asyncComponent(() => import("./ContactUs/ContactUsDetails"))}
      />
      <Route
        path={`${match.url}coupons`}
        component={asyncComponent(() => import("./Coupons"))}
      />
      <Route
        path={`${match.url}shop-orders`}
        component={asyncComponent(() => import("./ShopOrders"))}
      />
      <Route
        path={`${match.url}editVariations`}
        component={asyncComponent(() => import("./Variations/EditVariations"))}
      />
      <Route
        path={`${match.url}addVariations`}
        component={asyncComponent(() => import("./Variations/AddVariations"))}
      />
      <Route
        path={`${match.url}addCategories`}
        component={asyncComponent(() => import("./Categories/AddCategory"))}
      />
      <Route
        path={`${match.url}editCategories`}
        component={asyncComponent(() => import("./Categories/EditCategory"))}
      />
      <Route
        path={`${match.url}addProduct`}
        component={asyncComponent(() =>
          import("./ProductsModule/AddEditProduct")
        )}
      />
      <Route
        path={`${match.url}editProduct/:productId`}
        component={asyncComponent(() =>
          import("./ProductsModule/AddEditProduct")
        )}
      />
      <Route
        path={`${match.url}shipping`}
        component={asyncComponent(() => import("./Shipping"))}
      />
      <Route
        path={`${match.url}reportsPage`}
        component={asyncComponent(() => import("./Reports"))}
      />
      <Route
        path={`${match.url}storeLocation`}
        component={asyncComponent(() => import("./StoreLocations"))}
      />
      <Route
        path={`${match.url}addStoreLocations`}
        component={asyncComponent(() =>
          import("./StoreLocations/AddStoreLocations")
        )}
      />
      <Route
        path={`${match.url}editStoreLocations`}
        component={asyncComponent(() =>
          import("./StoreLocations/EditStoreLocations")
        )}
      />
      <Route
        path={`${match.url}addressBook`}
        component={asyncComponent(() => import("./AddressBook"))}
      />
      <Route
        path={`${match.url}addAddressBook`}
        component={asyncComponent(() => import("./AddressBook/AddAddressBook"))}
      />
      <Route
        path={`${match.url}editAddressBook`}
        component={asyncComponent(() =>
          import("./AddressBook/EditAddressBook")
        )}
      />
      <Route
        path={`${match.url}profile`}
        component={asyncComponent(() => import("./Profile"))}
      />
      <Route
        path={`${match.url}reviews`}
        component={asyncComponent(() => import("./Reviews"))}
      />
      <Route
        path={`${match.url}purchaseOrder`}
        component={asyncComponent(() => import("./PurchaseOrder"))}
      />
      <Route
        path={`${match.url}addPurchaseOrder`}
        component={asyncComponent(() =>
          import("./PurchaseOrder/AddPurchaseOrder")
        )}
      />
      <Route
        path={`${match.url}purchaseOrderDetails`}
        component={asyncComponent(() =>
          import("./PurchaseOrder/PurchaseOrderDetails")
        )}
      />
      <Route
        path={`${match.url}employeeNotes`}
        component={asyncComponent(() => import("./EmployeeNotes"))}
      />
      <Route
        path={`${match.url}createOrder`}
        component={asyncComponent(() => import("./CreateOrder"))}
      />
      <Route
        path={`${match.url}stockLocation`}
        component={asyncComponent(() => import("./StockLocations"))}
      />
      <Route
        path={`${match.url}addStockLocations`}
        component={asyncComponent(() =>
          import("./StockLocations/AddStockLocation")
        )}
      />
      <Route
        path={`${match.url}editStockLocations`}
        component={asyncComponent(() =>
          import("./StockLocations/EditStockLocation")
        )}
      />
      <Route
        path={`${match.url}stateSalesTax`}
        component={asyncComponent(() => import("./StateSalesTax"))}
      />
      <Route
        path={`${match.url}editStateSalesTax`}
        component={asyncComponent(() =>
          import("./StateSalesTax/EditStateSalesTax")
        )}
      />
      <Route
        path={`${match.url}settings`}
        component={asyncComponent(() => import("./SiteSettings"))}
      />
      <Route
        path={`${match.url}editSiteSettings`}
        component={asyncComponent(() =>
          import("./SiteSettings/EditSiteSettings")
        )}
      />
      <Route
        path={`${match.url}addSiteSettings`}
        component={asyncComponent(() =>
          import("./SiteSettings/AddSiteSettings")
        )}
      />
      <Route
        path={`${match.url}addProductByOrder`}
        component={asyncComponent(() => import("./AddProductByOrder"))}
      />
      <Route
        path={`${match.url}createOrderWithProducts`}
        component={asyncComponent(() =>
          import("./CreateOrder/CreateOrderWithProducts")
        )}
      />
      <Route
        path={`${match.url}duplicateOrderCreate`}
        component={asyncComponent(() => import("./DuplicateOrder"))}
      />
      <Route
        path={`${match.url}duplicateProductEdit`}
        component={asyncComponent(() =>
          import("./DuplicateOrder/DuplicateProductEdit")
        )}
      />
    </Switch>
  </div>
);

export default App;
